import React from "react";
import ReactDOM from "react-dom";
import ApiService from "../../services/DataAccess/ApiService";
import DataActions from "../../services/DataAccess/DataActions";
import DataStore from "../../services/DataAccess/DataStore";
import InboxItem from "./InboxItem";
import RoutingService from "../../services/RoutingService";
import { ContextMenu, ContextMenuTrigger } from "react-contextmenu";
import { MenuItem as ContextMenuItem } from "react-contextmenu";
import ModalService from "../../services/ModalService";
import UserInfoService from "../../services/UserInfoService";
import { AxoListGroup, AxoListItem } from "../../utilities/AxoListGroup";
import MessageService from "../../services/MessageService";
import LabelModal from "../Labels/LabelModal";
import { withRouter } from "react-router-dom";
import { SmallOrSmaller, MediumOrLarger } from "../../utilities/Responsive";
import { debounce } from "lodash";
import { AutoSizer, List } from "react-virtualized";
import "react-virtualized/styles.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import moment from "moment";

import {
  Grid,
  Row,
  Col,
  Button,
  ButtonGroup,
  ButtonToolbar,
  FormControl,
  InputGroup,
  Panel,
  PanelGroup,
} from "react-bootstrap";

import { AuthorizationService } from "../../services/AxoServices";

import {
  LexButton,
  DragDropList,
  AxoCheckbox,
  Icon,
  LabelSearchBox,
  AccordionUtils,
  Dimensions,
  AxoLocal,
  LoadingIcon,
  CaseSearchBox,
  FlexElement,
  Flexbox,
  getText,
  AsyncButton,
  TextModal,
} from "../../utilities/LexUtilities";

const EmailAccountStatus = {
  LOADING: 0,
  FAILURE: 1,
  SUCCESS: 2,
};

class InboxNavItem extends React.PureComponent {
  render() {
    return (
      <div className="flexbox-layout">
        <div style={{ paddingLeft: "25px" }}>
          <Icon glyph={this.props.glyph} />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span>{this.props.title}</span>
        </div>
        <div style={{ paddingRight: "25px" }}>
          <div>{this.props.labelValue}</div>
        </div>
      </div>
    );
  }
}

class InboxView extends React.PureComponent {
  handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.history.push(RoutingService.getPath("mailbox/compose"));
  };

  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      shown: "inbox",
      isDeleting: false,
      openFolders: true,
      openLabels: false,
      openAccounts: false,
      showLeftPanel: true,
      loadingMessages: false,
      localQueryParams: { ...props.queryParams },
    };

    this.textModal = React.createRef();
  }

  componentDidMount() {
    let { queryParams } = this.props;
    this.setState({
      localQueryParams: { ...queryParams },
    });
  }

  componentDidUpdate() {
    let { messageQuery } = this.props;

    let { loadingMessages } = this.state;
    if (loadingMessages !== messageQuery.isFetching) {
      this.setState({ loadingMessages: messageQuery.isFetching });
    }
  }

  onMailSelected = (selectedMessage, select) => {
    var selected = this.state.selected;
    if (select) {
      this.setState({ selected: [...selected, selectedMessage] });
    } else {
      this.setState({
        selected: selected.filter(
          (message) => message.id !== selectedMessage.id
        ),
      });
    }
  };

  onSelectAll = () => {
    if (this.state.selected.length > 0) {
      this.setState({ selected: [] });
    } else {
      var selected = [];
      this.getShownMessages().forEach((message) => {
        selected.push(message);
      });
      this.setState({ selected: selected });
    }
  };

  onDelete = (e) => {
    this.deleteMessages(this.state.selected);
    this.setState({ selected: [] });
  };

  onDeleteMail = (event, data) => {
    this.deleteMessages([data.message]);
  };

  onRestore = () => {
    this.restoreMessages(this.state.selected);
    this.setState({ selected: [] });
  };

  onRestoreMail = (event, data) => {
    this.restoreMessages([data.message]);
  };

  onGoToLabels = (event, data) => {
    this.props.history.push(RoutingService.getPath("Labels"));
  };

  deleteMessages(messages) {
    if (messages.length === 0) {
      return;
    }

    this.doDeleteMessages(messages);
  }

  doDeleteMessages = (messages) => {
    let { updateMessageFromHeader, deleteMessage } = this.props;
    let { shown } = this.state;

    this.setState({ isDeleting: true });

    var moveToTrash = !this.isTrashCan() && shown !== "spam";
    if (moveToTrash) {
      var deletionPromises = [];
      messages.forEach((currentMessage) => {
        deletionPromises.push(
          updateMessageFromHeader({
            ...currentMessage,
            trashed: true,
          }).unwrap()
        );
      });
      Promise.all(deletionPromises).then((responses) => {
        this.setState({ isDeleting: false });
      });
    } else {
      var text =
        messages.length > 1 ? (
          <span>
            <AxoLocal entity="Documentmessagepropsmessagelength" />{" "}
            {messages.length} <AxoLocal entity="DocumentConfirmModalcurrent" />
          </span>
        ) : (
          <AxoLocal entity="DocumentmessageDataActions" />
        );
      ModalService.openConfirmModal(text, (value) => {
        if (value) {
          var deletionPromises = [];
          messages.forEach((currentMessage) => {
            deletionPromises.push(deleteMessage(currentMessage.id).unwrap());
          });
          Promise.all(deletionPromises).then((responses) => {
            this.setState({ isDeleting: false });
          });

          var externalMessages = messages.filter((m) => !!m.externalUniqueId);
          this.props.deleteExternalMessages(externalMessages);
        }
      });
    }
  };

  restoreMessages = (messages) => {
    let { updateMessageFromHeader } = this.props;

    if (messages.length === 0) {
      return;
    }
    messages.forEach((currentMessage) => {
      updateMessageFromHeader({
        ...currentMessage,
        trashed: false,
      });
    });
  };

  moveMessagesToInbox = async (messages) => {
    let { updateMessageFromHeader } = this.props;

    let promises = messages.map((m) =>
      updateMessageFromHeader({
        ...m,
        spam: false,
        //Ensures the message will be removed and replaced with the correct message when the folder is updated.
        //This is necessary since unique Id is tied to the folder.
        externalUniqueId: 0,
      }).unwrap()
    );

    try {
      await Promise.all(promises);
    } catch {
      return false;
    }

    let externalMessages = messages.filter((m) => !!m.externalUniqueId);
    await DataActions.moveExternalMessagesToInbox(externalMessages);

    this.setState({ selected: [] });

    return true;
  };

  moveMessagesToSpam = async (messages) => {
    let { updateMessageFromHeader } = this.props;

    let promises = messages.map((m) =>
      updateMessageFromHeader({
        ...m,
        spam: true,
        //Ensures the message will be removed and replaced with the correct message when the folder is updated.
        //This is necessary since unique Id is tied to the folder.
        externalUniqueId: 0,
      }).unwrap()
    );

    try {
      await Promise.all(promises);
    } catch {
      return false;
    }

    let externalMessages = messages.filter((m) => !!m.externalUniqueId);
    await DataActions.moveExternalMessagesToSpam(externalMessages);

    this.setState({ selected: [] });

    return true;
  };

  getShownMessages = () => {
    let { shown, localQueryParams } = this.state;
    let { messageMap, emailAccounts, labels, userSettings } = this.props;
    let messages = [];
    if (shown === "inbox") {
      if (
        !!userSettings &&
        !!userSettings.emailAccountId &&
        userSettings.useStandardEmailAsInbox
      ) {
        messages = messageMap.received.filter(
          (m) => m.externalAccountId === userSettings.emailAccountId
        );
      } else {
        messages = messageMap.received;
      }
    } else if (shown === "spam") {
      messages = messageMap.spam;
    } else if (shown === "important") {
      messages = messageMap.important;
    } else if (shown === "sent") {
      messages = messageMap.sent;
    } else if (shown === "drafts") {
      messages = messageMap.drafts;
    } else if (shown === "trash") {
      messages = messageMap.trashed;
    } else {
      var account = emailAccounts.find(
        (a) => "account-" + a.id.toString() === shown
      );
      if (account) {
        messages = messageMap.received.filter(
          (m) => m.externalAccountId === account.id
        );
      }
      var label = labels.find((l) => "label-" + l.id.toString() === shown);
      if (label) {
        messages = messageMap.received.filter((m) =>
          label.messageIds.includes(m.id)
        );
      }
    }
    if (!!localQueryParams.searchText) {
      let search = localQueryParams.searchText.toLowerCase().trim();
      messages = messages.filter((message) => {
        return (
          (this.stringIncludes(message.externalSender, search) ||
            this.stringIncludes(message.externalSenderDisplayName, search) ||
            this.stringIncludes(message.externalMailReceiver, search) ||
            this.stringIncludes(message.externalReceiverDisplayName, search) ||
            this.stringIncludes(message.subject, search) ||
            this.stringIncludes(message.note, search)) &&
          this.dateRangeValid(message)
        );
        // || (!!message.receiver && this.stringIncludes(message.receiver.userName, search))
        // || (!!message.sender && this.stringIncludes(message.sender.userName, search))
      });
    }
    if (localQueryParams.onlyWithAttachments) {
      messages = messages.filter((m) => !!m.hasAttachments);
    }
    if (localQueryParams.onlyUnread) {
      messages = messages.filter((m) => !m.read);
    }

    return messages;
  };

  stringIncludes(text, search) {
    if (!text) {
      return false;
    }
    return text.toLowerCase().includes(search);
  }

  dateRangeValid(message) {
    let { localQueryParams } = this.state;
    let { startDate, endDate } = localQueryParams;

    if (!startDate && !endDate) {
      return true;
    }

    let messageDate = moment.utc(message.date).startOf("day");
    if (!!startDate && messageDate.isBefore(startDate)) {
      return false;
    }
    if (!!endDate && messageDate.isAfter(endDate)) {
      return false;
    }

    return true;
  }

  displayMoreButton = () => {
    let { shown, localQueryParams } = this.state;
    let { messageMap, pageSize } = this.props;

    if (
      !!localQueryParams.searchText ||
      localQueryParams.startDate ||
      localQueryParams.endDate
    ) {
      //Show more button if a full page has been loaded.
      if (shown === "inbox") {
        return messageMap.received.length >= pageSize;
      }
      if (shown === "spam") {
        return messageMap.spam.length >= pageSize;
      } else if (shown === "important") {
        return messageMap.important.length >= pageSize;
      } else if (shown === "sent") {
        return messageMap.sent.length >= pageSize;
      } else if (shown === "trash") {
        return messageMap.trashed.length >= pageSize;
      }
    }

    return this.moreMessagesAvailable();
  };

  moreMessagesAvailable = () => {
    let { shown } = this.state;
    let { messageCounter, messageMap } = this.props;

    if (shown === "inbox") {
      return messageCounter.receivedCount > messageMap.received.length;
    }
    if (shown === "spam") {
      return messageCounter.spamCount > messageMap.spam.length;
    } else if (shown === "important") {
      return messageCounter.importantCount > messageMap.important.length;
    } else if (shown === "sent") {
      return messageCounter.sentCount > messageMap.sent.length;
    } else if (shown === "drafts") {
      return false;
    } else if (shown === "trash") {
      return messageCounter.trashedCount > messageMap.trashed.length;
    }
    //When showing received mails for a specific label, all mails should be loaded automatically
    else if (shown.includes("label-")) {
      return false;
    }
    //When showing received mails for a specific account
    else {
      return messageCounter.receivedCount > messageMap.received.length;
    }
  };

  isTrashCan = () => {
    return this.state.shown === "trash";
  };

  handleReplyClick = (e) => {
    //Retrieve the full message
    ApiService.getMessage(this.state.selected[0].id).then((fullMessage) => {
      DataStore.setCachedMessage(
        MessageService.makeReplyMessage({
          message: fullMessage,
          replyAll: false,
        })
      );
      this.props.history.push(RoutingService.getPath("mailbox/compose/"));
    });
  };

  onForwardMessage = () => {
    //Retrieve the full message
    ApiService.getMessage(this.state.selected[0].id).then((fullMessage) => {
      DataStore.setCachedMessage(
        MessageService.makeForwardMessage(fullMessage)
      );
      this.props.history.push(RoutingService.getPath("mailbox/compose/"));
    });
  };

  onLabelSelected = (label) => {
    this.state.selected.forEach((selectedMail) => {
      DataActions.addLabelToMessage(selectedMail.id, label.id);
      this.setState({ selected: [] });
    });
  };

  updateShown = (shown) => {
    let { localQueryParams } = this.state;

    let newQuery = localQueryParams;
    if (!!localQueryParams.labelId && !shown.includes("label")) {
      newQuery = {
        ...localQueryParams,
        labelId: null,
      };
    }

    if (!!localQueryParams.externalAccountId && !shown.includes("account")) {
      newQuery = {
        ...localQueryParams,
        externalAccountId: null,
      };
    }

    this.setState({ shown });

    if(newQuery !== localQueryParams) {
      this.setState({ localQueryParams: newQuery });
      DataStore.setMessageQuery(newQuery);
    }

    //Close all when selecting on xs screens
    if (window.innerWidth < 992) {
      this.setState({
        // openFolders: false,
        // openLabels: false,
        // openAccounts: false,
        showLeftPanel: false,
      });
    }
  };

  onEditAccount = (accountShown) => {
    var id = parseInt(accountShown.replace("account-", ""), 10);
    this.props.history.push(RoutingService.getPath("mailbox/setup/edit/" + id));
  };

  countMails = (predicate) => {
    var { messageMap } = this.props;
    var mails = messageMap.received;
    var count = 0;
    for (var i = 0; i < mails.length; ++i) {
      if (predicate(mails[i])) {
        count++;
      }
    }
    return count;
  };

  onConfigure = () => {
    this.props.history.push(RoutingService.getPath("mailbox/internalSetup/"));
  };

  renderLeftPanel = () => {
    var {
      messageMap,
      messageCounter,
      emailAccounts,
      includeAccounts,
      accountStatuses,
      onDeleteAccount,
      confirmDeletionAccountId,
      onConfirmAccountDeletion,
      onCancelAccountDeletion,
      accountDeletionInProgress,
      accountOrder,
      updateAccountOrder,
      includeLabels,
      labels,
      labelOrder,
      updateLabelOrder,
      isAddingLabel,
      onToggleLabelCreation,
      newLabelName,
      onUpdateNewLabelName,
      onCreateLabel,
    } = this.props;

    var { openFolders, openLabels, openAccounts } = this.state;

    var { shown } = this.state;
    var accountSelected = emailAccounts.find(
      (a) => "account-" + a.id.toString() === shown
    );
    var accountForDeletion = emailAccounts.find(
      (a) => a.id === confirmDeletionAccountId
    );

    return (
      <div>
        <PanelGroup
          activeKey={openFolders ? "1" : "0"}
          onSelect={() => {
            this.setState({ openFolders: !openFolders });
          }}
          id="ITsubscriptionb"
          accordion
          className="Lex-Accordion"
          style={{
            borderRadius: "0px",
            paddingTop: "0px",
            borderBottom: "1px solid white",
          }}
        >
          <Panel className="backgroundlineargradient3" eventKey="1">
            <Panel.Heading>
              <Panel.Title toggle>
                {AccordionUtils.renderAccordionHeader(
                  <span>
                    <AxoLocal
                      entity="inbosViewBokse"
                      defaultValue={"Axo-Bokse"}
                    />{" "}
                  </span>
                )}
              </Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible className="LexAccordionPanelody">
              <AxoListGroup style={{ paddingBottom: "10px" }}>
                <AxoListItem
                  className="lismailinbox Mailiconcolor"
                  onClick={this.updateShown.bind(this, "inbox")}
                  active={shown === "inbox"}
                >
                  <InboxNavItem
                    glyph="icon-fontello-mail-1"
                    title={
                      <AxoLocal
                        entity="inbosViewInbox"
                        defaultValue={"Inbox"}
                      />
                    }
                    labelValue={
                      <span>
                        {messageCounter.receivedCount} (
                        {messageCounter.unreadCount})
                      </span>
                    }
                  />
                </AxoListItem>
                <AxoListItem
                  className="lismailinbox"
                  onClick={this.updateShown.bind(this, "important")}
                  active={shown === "important"}
                >
                  <InboxNavItem
                    glyph="icon-fontello-star-empty-1"
                    title={
                      <AxoLocal
                        entity="inbosViewimportant"
                        defaultValue={"Vigtige"}
                      />
                    }
                    labelValue={messageCounter.importantCount}
                  />
                </AxoListItem>
                <AxoListItem
                  className="lismailinbox"
                  onClick={this.updateShown.bind(this, "sent")}
                  active={shown === "sent"}
                >
                  <InboxNavItem
                    glyph="icon-fontello-mail-1"
                    title={
                      <AxoLocal entity="inbosViewsent" defaultValue={"Sendt"} />
                    }
                    labelValue=""
                  />
                </AxoListItem>
                <AxoListItem
                  className="lismailinbox"
                  onClick={this.updateShown.bind(this, "drafts")}
                  active={shown === "drafts"}
                >
                  <InboxNavItem
                    glyph="icon-fontello-inbox-4"
                    title={
                      <AxoLocal
                        entity="inbosViewdrafts"
                        defaultValue={"Kladder"}
                      />
                    }
                    labelValue={messageMap.drafts.length}
                  />
                </AxoListItem>
                <AxoListItem
                  className="lismailinbox"
                  onClick={this.updateShown.bind(this, "spam")}
                  active={shown === "spam"}
                >
                  <InboxNavItem
                    glyph="icon-fontello-star-empty-1"
                    title={
                      <span>
                        <AxoLocal
                          entity="axoEntityidcode67"
                          defaultValue={"Spam"}
                        />
                      </span>
                    }
                    labelValue={messageCounter.spamCount}
                  />
                </AxoListItem>
                <AxoListItem
                  className="lismailinbox"
                  onClick={this.updateShown.bind(this, "trash")}
                  active={shown === "trash"}
                >
                  <InboxNavItem
                    glyph="icon-fontello-trash-1"
                    title={
                      <AxoLocal
                        entity="inbosViewtrashed"
                        defaultValue={"Papirkurv"}
                      />
                    }
                    labelValue={messageCounter.trashedCount}
                  />
                </AxoListItem>
              </AxoListGroup>
              <LexButton
                onClick={() =>
                  this.props.history.push(
                    RoutingService.getPath("mailbox/internalSetup/")
                  )
                }
              >
                <AxoLocal
                  entity="AccountingTabViewEntity65"
                  defaultValue={"Email opsætning"}
                />
                &nbsp;&nbsp;
                <Icon glyph="icon-fontello-cog-1" />
              </LexButton>
            </Panel.Body>
          </Panel>
        </PanelGroup>
        {includeLabels ? (
          <PanelGroup
            activeKey={openLabels ? "1" : "0"}
            onSelect={() => {
              this.setState({ openLabels: !openLabels });
            }}
            id="ITsubscriptionb"
            accordion
            className="Lex-Accordion"
            style={{
              borderRadius: "0px",
              paddingTop: "0px",
              borderBottom: "1px solid white",
            }}
          >
            <Panel className="backgroundlineargradient3" eventKey="1">
              <Panel.Heading>
                <Panel.Title toggle>
                  {AccordionUtils.renderAccordionHeader(
                    <span>
                      <AxoLocal
                        entity="CaseDataTableFixedThefolders"
                        defaultValue={"Mapper"}
                      />
                    </span>
                  )}
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body collapsible className="LexAccordionPanelody">
                <div>
                  <div className="text-right">
                    <LexButton
                      onClick={onToggleLabelCreation}
                      style={{ fontSize: "28px", lineHeight: "30px" }}
                    >
                      {isAddingLabel ? <span>-</span> : <span>+</span>}
                    </LexButton>
                  </div>
                  {isAddingLabel ? (
                    <div>
                      <InputGroup style={{ maxWidth: "250px" }}>
                        <AxoLocal
                          componentClass={FormControl}
                          type="text"
                          style={{
                            borderColor: "#4CAF50",
                            borderWidth: "1px",
                            borderRadius: "4px",
                          }}
                          value={newLabelName}
                          onChange={onUpdateNewLabelName}
                          componentAttribute="placeholder"
                          entity="placeholderEnterthefoldername"
                        />
                        <InputGroup.Addon className="plain">
                          <Button
                            className="Lex-button-2"
                            onClick={onCreateLabel}
                          >
                            <AxoLocal
                              entity="ADDViewCreate"
                              defaultValue={"Opret"}
                            />
                            {this.state.showCreatedLabelIcon ? (
                              <span>
                                &nbsp;
                                <Icon
                                  className="axoblue"
                                  glyph="icon-fontello-ok-3"
                                />{" "}
                                &nbsp; &nbsp;
                              </span>
                            ) : null}
                          </Button>
                        </InputGroup.Addon>
                      </InputGroup>
                    </div>
                  ) : null}
                  <DragDropList
                    items={this.getLabelNodes(labels, shown)}
                    order={labelOrder}
                    updateOrder={updateLabelOrder}
                  />
                  {this.getLabelMenus(labels)}
                </div>
              </Panel.Body>
            </Panel>
          </PanelGroup>
        ) : null}
        {includeAccounts ? (
          <PanelGroup
            id="ITsubscriptionb"
            activeKey={openAccounts ? "1" : "0"}
            onSelect={() => {
              this.setState({ openAccounts: !openAccounts });
            }}
            accordion
            className="Lex-Accordion"
            defaultActiveKey="0"
            style={{
              borderRadius: "0px",
              paddingTop: "0px",
              borderBottom: "1px solid white",
            }}
          >
            <Panel className="backgroundlineargradient3" eventKey="1">
              <Panel.Heading>
                <Panel.Title toggle>
                  {AccordionUtils.renderAccordionHeader(
                    <span>
                      <AxoLocal
                        entity="inbosViewkonto1"
                        defaultValue={"Email konti"}
                      />
                      {Object.keys(accountStatuses).find(
                        (key) =>
                          accountStatuses[key] === EmailAccountStatus.FAILURE
                      ) && (
                        <>
                          &nbsp;&nbsp;&nbsp;
                          <span className="axored">Fejl i email konto</span>
                        </>
                      )}
                    </span>
                  )}
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body collapsible className="LexAccordionPanelody">
                <div className="flexbox-layout">
                  <div
                    className="flexbox-center"
                    style={{ display: "inline-block" }}
                  >
                    <Button
                      onClick={this.onGotoSetup}
                      className="Lex-button-2"
                      style={{ fontSize: "28px", lineHeight: "30px" }}
                    >
                      +
                    </Button>{" "}
                    &nbsp;
                  </div>
                  {accountSelected ? (
                    <div>
                      <LexButton
                        onClick={this.onEditAccount.bind(this, shown)}
                        style={{ marginRight: "10px" }}
                      >
                        <AxoLocal
                          entity="inbosViewkonto2"
                          defaultValue={"Rediger"}
                        />
                      </LexButton>
                      <LexButton
                        onClick={() =>
                          onDeleteAccount(
                            parseInt(shown.replace("account-", ""), 10)
                          )
                        }
                      >
                        <AxoLocal
                          entity="inbosViewkonto3"
                          defaultValue={"Slet konto"}
                        />
                      </LexButton>
                    </div>
                  ) : null}
                </div>
                {accountForDeletion ? (
                  <div className="flexbox-layout">
                    <h4>
                      <AxoLocal
                        entity="inbosViewkonto3"
                        defaultValue={"Slet konto"}
                      />
                      :{" "}
                      {accountForDeletion.displayName ||
                        accountForDeletion.account}
                      ?
                    </h4>
                    <LexButton
                      onClick={async () => {
                        await onConfirmAccountDeletion();
                        this.setState({ shown: "inbox" });
                      }}
                      disabled={accountDeletionInProgress}
                    >
                      <AxoLocal
                        entity="AlertshowModaldialogOk"
                        defaultValue={"OK"}
                      />
                      {accountDeletionInProgress ? (
                        <span>
                          &nbsp;
                          <img
                            alt=""
                            src="/imgs/app/loading.gif"
                            className="img-circle"
                            width="20"
                            height="20"
                          />
                        </span>
                      ) : null}
                    </LexButton>
                    <LexButton
                      onClick={onCancelAccountDeletion}
                      disabled={accountDeletionInProgress}
                    >
                      <AxoLocal
                        entity="axoAccounting22"
                        defaultValue={"Fortryd"}
                      />
                    </LexButton>
                  </div>
                ) : null}
                <DragDropList
                  items={this.getAccountNodes(
                    emailAccounts,
                    accountStatuses,
                    shown
                  )}
                  order={accountOrder}
                  updateOrder={updateAccountOrder}
                />
              </Panel.Body>
            </Panel>
          </PanelGroup>
        ) : null}
      </div>
    );
  };

  getAccountNodes = (emailAccounts, accountStatuses, shown) => {

    let AccountListItem = this.AccountListItem;
    return emailAccounts.map((account) => {
      return (
        <AccountListItem
          key={account.id}
          account={account}
          accountStatuses={accountStatuses}
          shown={shown}
          updateShown={this.updateShown}
          countMails={this.countMails}
        />
      );
    });
  };

  AccountListItem = (props) => {
    let { account, accountStatuses, shown } = props;

    let loading =
      !accountStatuses[account.id] ||
      accountStatuses[account.id] === EmailAccountStatus.LOADING;
    let failure = accountStatuses[account.id] === EmailAccountStatus.FAILURE;

    const getStatusMessage = () => {
      if (loading) {
        return (
          <span>
            &nbsp;
            <img
              alt=""
              src="/imgs/app/loading.gif"
              className="img-circle"
              width="20"
              height="20"
            />
          </span>
        );
      } else if (failure) {
        return (
          <span>
            &nbsp;
            <AxoLocal
              entity="inbosViewkonto4"
              defaultValue={"(Fejl)"}
            />{" "}
          </span>
        );
      }
      return "";
    };

    let className = failure ? "flexbox-layout axored" : "flexbox-layout";
    return (
      <AxoListItem
        key={account.id}
        onClick={() => {
          this.showAccountContent(account.id);
        }}
        className="lismailinbox"
        active={shown === "account-" + account.id.toString()}
      >
        <div
          className={className}
          style={{ paddingLeft: "15px", paddingRight: "15px" }}
        >
          <div>{account.displayName || account.account}{getStatusMessage()}</div>
          {/* <div>{getStatusMessage()}</div> */}
        </div>
      </AxoListItem>
    );
  };

  showAccountContent = (accountId) => {
    let { localQueryParams } = this.state;
    let newParams = {
      ...localQueryParams,
      externalAccountId: accountId,
    };

    this.setState({ localQueryParams: newParams });
    DataStore.setMessageQuery(newParams);

    this.updateShown("account-" + accountId.toString());
  };

  getLabelNodes = (labels, shown) => {
    return labels.map((label) => {
      return (
        <ContextMenuTrigger id={"label-context-menu-" + label.id}>
          <AxoListItem
            key={label.id}
            onClick={() => {
              this.showLabelContent(label);
            }}
            className="lismailinbox"
            active={shown === "label-" + label.id.toString()}
          >
            <div
              className="flexbox-layout"
              style={{ paddingLeft: "15px", paddingRight: "15px" }}
            >
              <div>{label.name}</div>
              <div>{label.messageIds.length}</div>
            </div>
          </AxoListItem>
        </ContextMenuTrigger>
      );
    });
  };

  showLabelContent = (label) => {
    let { localQueryParams } = this.state;
    let newParams = {
      ...localQueryParams,
      labelId: label.id,
    };

    this.setState({ localQueryParams: newParams})
    DataStore.setMessageQuery(newParams);

    this.updateShown("label-" + label.id.toString());
  };

  onRenameLabel = (e, data) => {
    this.labelModal.setEdit(data.label).open();
  };

  onDeleteLabel = (e, data) => {
    ModalService.openConfirmModal(
      <span>
        ({data.label.name}){" "}
        <AxoLocal
          entity="ThrashEntriesViewDeletePermanently"
          defaultValue={"Slet permanent?"}
        />
      </span>,
      (value) => {
        if (value) {
          DataActions.deleteLabel(data.label.id);
        }
      }
    );
  };

  getLabelMenus = (labels) => {
    var contextMenus = labels.map((label) => {
      return (
        <ContextMenu key={label.id} id={"label-context-menu-" + label.id}>
          <ContextMenuItem onClick={this.onRenameLabel} data={{ label: label }}>
            <AxoLocal
              entity="LabelInboxonRenameLabel"
              defaultValue={"Omdøb mappe"}
            />
          </ContextMenuItem>
          <ContextMenuItem onClick={this.onDeleteLabel} data={{ label: label }}>
            <AxoLocal
              entity="LabelInboxDeleteLabel"
              defaultValue={"Slet mappe"}
            />
          </ContextMenuItem>
        </ContextMenu>
      );
    });
    return contextMenus;
  };

  onSearch = (queryParams) => {
    let { loadingMessages } = this.state;
    if (loadingMessages) {
      return;
    }

    this.setState({
      localQueryParams: queryParams,
    });

    this.fetchMessagesDebounced(queryParams);
  };

  fetchMessagesDebounced = debounce(async (query) => {
    DataStore.resetMessagePageSize(); //Only search for the default amount of messages.
    DataStore.setMessageQuery(query);
  }, 500);

  onGotoSetup = () => {
    this.props.history.push(RoutingService.getPath("mailbox/setup"));
  };

  LabelDropDown = (props) => {
    let { selected, labels } = props;
    let { loadingMessages } = this.state;

    let availableLabels = [];
    //Only show labels not used by at least one of the messages
    availableLabels = labels.filter((l) =>
      selected.find(
        (message) => !l.messageIds.find((id) => id === parseInt(message.id, 10))
      )
    );

    return (
      <div style={{ display: "inline-block" }}>
        {/* <Icon glyph='icon-fontello-folder-open-1'/>&ensp;&ensp;
        <AxoLocal key='maillabel' entity='inboxSelectafolder'defaultValue={'Vælg en mappe'}/>   */}
        <AxoLocal
          componentClass={LabelSearchBox}
          maxWidth="250px"
          labels={availableLabels}
          handleSelectedLabel={this.onLabelSelected}
          disabled={selected.length === 0 || loadingMessages}
          inputBorder="1px solid #4CAF50"
          clearOnSelect
          componentAttribute="placeholder"
          entity="LabelInboxPanelLabelA"
        />
      </div>
    );
  };

  onAttachCase = (selectedCase) => {
    let { updateMessageFromHeader } = this.props;

    this.state.selected.forEach((selectedMail) => {
      updateMessageFromHeader({
        ...selectedMail,
        caseModelId: selectedCase.id,
        caseModel: selectedCase,
      });
    });
    this.setState({ selected: [] });
  };

  CaseDropDown = () => {
    let { caseMap } = this.props;
    let { selected, loadingMessages } = this.state;

    return (
      <div>
        <CaseSearchBox
          maxWidth="250px"
          cases={caseMap.saved}
          handleSelectedCase={this.onAttachCase}
          disabled={selected.length === 0 || loadingMessages}
          inputBorder="1px solid #4CAF50"
          clearOnSelect
          placeholder={getText("DocumentGridViewCaseNodes", "Tilføj til sag")}
        />
      </div>
    );
  };

  inboxItemRenderer(messageNodes, { key, index, style }) {
    return (
      <div key={key} style={style}>
        {messageNodes[index]}
      </div>
    );
  }

  renderMailSection = () => {
    let {
      onShowMore,
      userProfile,
      containerHeight,
      query,
      toggleImportance,
      toggleRead,
      updateMessageFromHeader,
    } = this.props;
    let { shown, loadingMessages } = this.state;

    let shownMessages = this.getShownMessages();
    let messageNodes = [];
    messageNodes = shownMessages.map((message) => {
      var isSelected =
        this.state.selected.find(
          (currentMessage) => currentMessage.id === message.id
        ) !== undefined;
      return (
        <div>
          <InboxItem
            key={message.id}
            message={message}
            userProfile={userProfile}
            imgClass=""
            labelValue={
              <AxoLocal entity="axoidcode151" defaultValue={"Emne:"} />
            }
            labelClass="axoblue"
            showImportant={!this.isTrashCan()}
            shown={shown}
            date={message.date}
            toggleImportance={toggleImportance}
            toggleRead={toggleRead}
            selected={isSelected}
            onSelect={this.onMailSelected}
          />
          {ReactDOM.createPortal(
            <ContextMenu
              key={message.id}
              id={"message-context-menu-" + message.id}
            >
              <ContextMenuItem onClick={this.onDeleteMail} data={{ message }}>
                <AxoLocal entity="MailinbosView1" defaultValue="Slet mail" />
              </ContextMenuItem>
              {this.isTrashCan() && (
                <ContextMenuItem
                  onClick={this.onRestoreMail}
                  data={{ message }}
                >
                  <AxoLocal
                    entity="MailinbosView2"
                    defaultValue="Gendan mail"
                  />
                </ContextMenuItem>
              )}
              {shown === "inbox" && (
                <ContextMenuItem
                  onClick={() => this.moveMessagesToSpam([message])}
                >
                  <AxoLocal
                    entity="axoEntityidcode68"
                    defaultValue={"Marker spam"}
                  />
                </ContextMenuItem>
              )}
              <ContextMenuItem
                onClick={() =>
                  this.textModal.current.open(message.note, (newNote) =>
                    updateMessageFromHeader({
                      ...message,
                      note: newNote,
                    })
                  )
                }
              >
                <AxoLocal
                  entity="axoEntityidcode69"
                  defaultValue={"Skriv note"}
                />
              </ContextMenuItem>
              {shown === "spam" && (
                <ContextMenuItem
                  onClick={() => this.moveMessagesToInbox([message])}
                >
                  <AxoLocal
                    entity="axoEntityidcode70"
                    defaultValue={"Flyt til indbakke"}
                  />
                </ContextMenuItem>
              )}
            </ContextMenu>,
            document.body
          )}
        </div>
      );
    });
    if (this.displayMoreButton()) {
      messageNodes.push(
        <div style={{ paddingTop: "5px" }}>
          <LexButton
            onClick={() => onShowMore(query)}
            disabled={loadingMessages}
            style={{ width: "100%", paddingBottom: "10px" }}
          >
            <AxoLocal entity="ViewShowmoreinfo" defaultValue={"Vis flere"} />
            {loadingMessages ? (
              <span>
                &nbsp;
                <img
                  alt=""
                  src="/imgs/app/loading.gif"
                  className="img-circle"
                  width="20"
                  height="20"
                />
              </span>
            ) : null}
          </LexButton>
        </div>
      );
    }
    return (
      <Grid fluid>
        <Row>
          <Col xs={12} style={{ height: containerHeight }}>
            <AutoSizer>
              {({ height, width }) => (
                <List
                  height={height}
                  rowCount={messageNodes.length}
                  rowHeight={90}
                  rowRenderer={(args) =>
                    this.inboxItemRenderer(messageNodes, args)
                  }
                  width={width}
                />
              )}
            </AutoSizer>
          </Col>
        </Row>
      </Grid>
    );
  };

  render() {
    let { selected, showLeftPanel, loadingMessages, shown, localQueryParams } =
      this.state;
    let { labels, includeLabels, containerHeight, userProfile } = this.props;

    let userType = AuthorizationService.getUserType(userProfile);
    let query = localQueryParams;
    let { searchText, startDate, endDate, onlyUnread, onlyWithAttachments } =
      query;

    let LabelDropDown = this.LabelDropDown;
    let CaseDropDown = this.CaseDropDown;
    return (
      <div>
        <Flexbox spaceBetween alignCenter style={{ paddingLeft: "15px" }}>
          <Flexbox alignCenter>
            <FlexElement className="rightPadding">
              <ButtonToolbar>
                <SmallOrSmaller>
                  {!showLeftPanel ? (
                    <LexButton
                      style={{ width: "100px" }}
                      onClick={() => this.setState({ showLeftPanel: true })}
                    >
                      <Icon glyph="icon-fontello-left-bold-1" />
                    </LexButton>
                  ) : null}
                </SmallOrSmaller>
                <AxoLocal
                  componentClass={Button}
                  className="Lex-button-2"
                  onClick={this.handleClick}
                  componentAttribute="title"
                  entity="inboxhandleNewMAil"
                >
                  <Icon glyph="icon-fontello-edit-1" />
                </AxoLocal>
                <ButtonGroup>
                  <AxoLocal
                    componentClass={Button}
                    className="Lex-button-2"
                    disabled={this.state.selected.length !== 1}
                    onClick={this.handleReplyClick}
                    componentAttribute="title"
                    entity="inboxhandleReplyClick"
                  >
                    <Icon glyph="icon-fontello-reply" />
                    {/* &nbsp;<AxoLocal entity='inboxhandleReplyClick' defaultValue='Besvar' /> */}
                  </AxoLocal>
                  <AxoLocal
                    componentClass={Button}
                    className="Lex-button-2"
                    disabled={this.state.selected.length !== 1}
                    onClick={this.onForwardMessage}
                    componentAttribute="title"
                    entity="inboxForwardButton"
                  >
                    <Icon glyph="icon-fontello-forward" />
                    {/* &nbsp;<AxoLocal entity='inboxForwardButton' defaultValue='Videresend' /> */}
                  </AxoLocal>
                  <AxoLocal
                    componentClass={Button}
                    className="Lex-button-2"
                    disabled={this.state.selected.length === 0}
                    onClick={this.onDelete}
                    componentAttribute="title"
                    entity="axoidcode179"
                  >
                    {this.state.isDeleting ? (
                      <img
                        alt=""
                        src="/imgs/app/loading.gif"
                        className="img-circle"
                        width="20"
                        height="20"
                      />
                    ) : (
                      <Icon glyph="icon-fontello-trash-1" />
                    )}
                  </AxoLocal>
                  {this.isTrashCan() ? (
                    <AxoLocal
                      componentClass={Button}
                      className="Lex-button-2"
                      disabled={this.state.selected.length === 0}
                      onClick={this.onRestore}
                      componentAttribute="title"
                      entity="DocumentTrashTableViewrestore"
                    >
                      <Icon glyph="icon-fontello-ccw-1" />
                    </AxoLocal>
                  ) : null}
                  {/* <Button disabled={isUpdating} onClick={onUpdate} className='Lex-button-2'>
                  <Icon glyph='icon-simple-line-icons-reload'/>&nbsp;&nbsp;
                  <AxoLocal entity='ResetPasswordRefresh' defaultValue={'Opdater'}/>  
                    { isUpdating ? (
                      <span>
                        &nbsp;<img alt=''  src='/imgs/app/loading.gif' className='img-circle' width='20' height='20' />
                      </span>
                    ) : null }
                </Button> */}
                </ButtonGroup>
              </ButtonToolbar>
            </FlexElement>
            <FlexElement className="rightPadding hidden-xs hidden-sm">
              <AxoCheckbox
                checked={
                  selected.length > 0 &&
                  selected.length === this.getShownMessages().length
                }
                onChange={this.onSelectAll}
              />
              <AxoLocal
                style={{ paddingLeft: "10px" }}
                entity="inboxmailSelectall"
                defaultValue={"Vælg alle"}
              />
            </FlexElement>
            {includeLabels && selected.length > 0 && shown !== "spam" && (
              <>
                <FlexElement className="rightPadding">
                  <LabelDropDown
                    labels={labels}
                    selected={selected}
                    onGoToLabels={this.onGoToLabels}
                    onLabelSelected={this.onLabelSelected}
                  />
                </FlexElement>
                {(userType === "Lawyer" || userType === "Admin") && (
                  <FlexElement className="rightPadding">
                    <CaseDropDown />
                  </FlexElement>
                )}
                <FlexElement className="rightPadding">
                  <AsyncButton
                    onClick={() => this.moveMessagesToSpam(selected)}
                    disabled={loadingMessages}
                  >
                    <AxoLocal
                      entity="axoEntityidcode68"
                      defaultValue={"Marker spam"}
                    />
                  </AsyncButton>
                </FlexElement>
              </>
            )}
            {selected.length > 0 && shown === "spam" && (
              <>
                <FlexElement className="rightPadding">
                  <AsyncButton
                    onClick={() => this.moveMessagesToInbox(selected)}
                    disabled={loadingMessages}
                  >
                    <AxoLocal
                      entity="axoEntityidcode71"
                      defaultValue={"Ikke spam"}
                    />
                  </AsyncButton>
                </FlexElement>
              </>
            )}
            <FlexElement className="rightPadding">
              {/* Søg... */}
              <AxoLocal
                componentClass={FormControl}
                type="text"
                value={searchText}
                style={{
                  display: "inline-block",
                  // maxWidth: '250px',
                  borderColor: !!searchText ? "#f96d00" : "",
                }}
                onChange={(event) =>
                  this.onSearch({ ...query, searchText: event.target.value })
                }
                disabled={loadingMessages}
                componentAttribute="placeholder"
                entity="ContactTableViewplaceholderonSearch"
              />
            </FlexElement>
            {selected.length === 0 && (
              <>
                <FlexElement className="rightPadding hidden-xs hidden-sm">
                  <DateRangePicker
                    startDate={startDate} // momentPropTypes.momentObj or null,
                    startDateId="startDate" // PropTypes.string.isRequired,
                    endDate={endDate} // momentPropTypes.momentObj or null,
                    endDateId="endDate" // PropTypes.string.isRequired,
                    isOutsideRange={() => false}
                    onDatesChange={({ startDate, endDate }) =>
                      this.onSearch({
                        ...query,
                        startDate: !!startDate
                          ? moment.utc(startDate.format("YYYY-MM-DD"))
                          : null,
                        endDate: !!endDate
                          ? moment.utc(endDate.format("YYYY-MM-DD"))
                          : null,
                      })
                    } // PropTypes.func.isRequired,
                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={(focusedInput) =>
                      this.setState({ focusedInput })
                    } // PropTypes.func.isRequired,
                    startDatePlaceholderText="Start dato"
                    endDatePlaceholderText="Slut dato"
                    disabled={loadingMessages}
                  />
                </FlexElement>
                {(!!startDate || !!endDate) && (
                  <FlexElement className="rightPadding">
                    <Icon
                      glyph="icon-fontello-scissors"
                      className="axored"
                      role="button"
                      onClick={() =>
                        this.onSearch({
                          ...query,
                          startDate: null,
                          endDate: null,
                        })
                      }
                    />
                  </FlexElement>
                )}
              </>
            )}
            <FlexElement className="rightPadding hidden-xs hidden-sm">
              <AxoCheckbox
                checked={onlyUnread}
                disabled={loadingMessages}
                onChange={() =>
                  this.onSearch({
                    ...query,
                    onlyUnread: !onlyUnread,
                  })
                }
              />
              <span>Kun ulæste</span>
            </FlexElement>
            <FlexElement className="rightPadding hidden-xs hidden-sm">
              <AxoCheckbox
                checked={onlyWithAttachments}
                disabled={loadingMessages}
                onChange={() =>
                  this.onSearch({
                    ...query,
                    onlyWithAttachments: !onlyWithAttachments,
                  })
                }
              />
              <span>Kun filer</span>
            </FlexElement>
            {loadingMessages && (
              <FlexElement>
                &nbsp;
                <LoadingIcon show={loadingMessages} />
              </FlexElement>
            )}
          </Flexbox>
          <div className="text-right hidden-xs hidden-sm hidden-md">
            <div className="flexbox-standard" style={{ paddingRight: "10px" }}>
              &nbsp;
              <img
                alt=""
                className="img-circle"
                src={UserInfoService.getMyProfileImageSource(
                  this.props.userProfile
                )}
                width="40"
                height="40"
              />
              &nbsp; &nbsp;
              <div className="axoinboxitemSubject text-left hidden-xs hidden-sm">
                <div>
                  {UserInfoService.getDisplayName(this.props.userProfile)}
                </div>
                <div>
                  <small>
                    {" "}
                    <AxoLocal entity="inboxMailbox" defaultValue={"Mailbox"} />
                  </small>
                </div>
              </div>
            </div>
          </div>
        </Flexbox>
        <hr style={{ margin: 0 }} />
        <Grid fluid>
          <Row>
            <SmallOrSmaller>
              {showLeftPanel ? (
                <Col sm={12} className="nopadding">
                  {this.renderLeftPanel()}
                </Col>
              ) : (
                <Col sm={12} className="nopadding">
                  <div style={{ paddingTop: "5px" }}>
                    {this.renderMailSection()}
                  </div>
                </Col>
              )}
            </SmallOrSmaller>
            <MediumOrLarger>
              <Col lg={3} md={4} className="nopadding">
                <div style={{ height: containerHeight, overflowY: "scroll" }}>
                  {this.renderLeftPanel()}
                </div>
              </Col>
              <Col lg={9} md={8} xs={12} className="nopadding">
                <div>{this.renderMailSection()}</div>
              </Col>
            </MediumOrLarger>
          </Row>
        </Grid>
        <LabelModal
          labels={this.props.labels}
          labelType={0}
          ref={(c) => (this.labelModal = c)}
        />
        <TextModal
          ref={this.textModal}
          title={getText("axoEntityidcode69", "Skriv note")}
        />
      </div>
    );
  }
}

export default withRouter(
  Dimensions({
    getHeight: function (element) {
      return window.innerHeight - 150;
    },
  })(InboxView)
);
